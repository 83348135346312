<template>
  <div class="privacy">
    <top-panel></top-panel>
    <cookie-panel></cookie-panel>
    <div class="privacy--container">
      <div class="global--section">
        <div class="global--section_container">
          <div class="global--animation_container">
            <div class="privacy--title">Privacy Policy</div>
            <div class="global--animation_overlay" v-in-viewport.once></div>
          </div>
          <div class="privacy--text_container global--animation_reveal" v-in-viewport.once>
            <div class="privacy--text">This Privacy Policy, in accordance with the Regulation EU 2016/679 protection of natural persons with regard to the processing of personal data and the free movement of such data applies between you, the User of this Website and FairCode Ltd, the owner and provider of this Website. FairCode Ltd takes the privacy of your information very seriously. This privacy policy applies to our use of any and all Data collected by us or provided by you in relation to your use of the Website. Please read this privacy policy carefully.</div>
            <div class="privacy--description">Definitions and interpretation</div>
            <div class="privacy--text">In this privacy policy, the following definitions are used: Data, collectively all information that you submit to FairCode Ltd via the Website. This definition incorporates, where applicable, the definitions provided in the Data Protection Laws; Cookies, a small text file placed on your computer by this Website when you visit certain parts of the Website and/or when you use certain features of the Website. Details of the cookies used by this Website are set out in the clause below; Data Protection Laws, any applicable law relating to the processing of personal Data, including but not limited to the GDPR, and any national implementing laws, regulations and secondary legislation, for as long as the GDPR is effective in the UK; GDPR, the General Data Protection Regulation (EU) 2016/679; FairCode Ltd, a company incorporated in England and Wales with registered number 11382758 whose registered office is at Kemp House, 160 City Road, EC1V 2NX, London, United Kingdom; UK and EU Cookie Law, the Privacy and Electronic Communications (EC Directive) Regulations 2003 as amended by the Privacy and Electronic Communications (EC Directive) (Amendment) Regulations 2011; User or you, any third party that accesses the Website and is not either (i) employed by FairCode Ltd and acting in the course of their employment or (ii) engaged as a consultant or otherwise providing services to FairCode Ltd and accessing the Website in connection with the provision of such services; and Website, the website that you are currently using, https://faircode.tech, and any sub-domains of this site unless expressly excluded by their own terms and conditions.</div>
            <div class="privacy--description">Scope of this privacy policy</div>
            <div class="privacy--text">In this privacy policy, unless the context requires a different interpretation: the singular includes the plural and vice versa; references to sub-clauses, clauses, schedules or appendices are to sub-clauses, clauses, schedules or appendices of this privacy policy; a reference to a person includes firms, companies, government entities, trusts and partnerships; “Including” is understood to mean “including without limitation”; references to any statutory provision includes and modification or amendment of it.</div>
            <div class="privacy--text">This privacy policy applies only to the actions of FairCode Ltd and Users with respect to this Website. It does not extend to any websites that can be accessed from this Website including, but not limited to, any links we may provide to social media websites. For purposes of the applicable Data Protection Laws, FairCode Ltd is the “data controller”. This means that FairCode Ltd determines the purposes for which, and the manner in which, your Data is processed.</div>
            <div class="privacy--description">Data collected</div>
            <div class="privacy--text">We may collect the following Data, which includes personal Data, from you: name; date of birth; gender; job title; profession; contact Information such as email addresses and telephone numbers; demographic information such as post code, preferences and interests; financial information such as credit/debit card numbers; IP address (automatically collected); web browser type and version (automatically collected); operating system (automatically collected); a list of URLs starting with a referring site, your activity on this Website, and the site you exit to (automatically collected); in each case, in accordance with this privacy policy.</div>
            <div class="privacy--description">How we collect</div>
            <div class="privacy--text">We collect Data in the following ways: data is given to us by you; and data is collected automatically. FairCode Ltd will collect your Data in a number of ways, for example: when you contact us through the Website, by telephone, post, e-mail or through any other means; when you complete surveys that we use for research purposes (although you are not obliged to respond to them); when you make payments to us, through this Website or otherwise; when you elect to receive marketing communication from us; when you use our services; in each case, in accordance with this privacy policy. To the extent that you access the Website, we will collect your Data automatically, for example: we automatically collect some information about your visit to the Website. This information helps us to make improvements to Website content and navigation, and includes your IP address, the date, times and frequency with which you access the Website and the way you use and interact with its content. We will collect your Data automatically via cookies, in line with the cookie settings on your browser.</div>
            <div class="privacy--description">Our use of Data</div>
            <div class="privacy--text">Any or all of the above Data may be required by us from time to time in order to provide you with the best possible service and experience when using our Website. Specifically, Data my be used by us for the following reasons: internal record keeping; improvement of our products/services; transmission by email of marketing materials that may be of interest to you; contact for market research purposes which may be done using email, telephone, fax or mail. Such information may be used to customize or update the Website; in each case, in accordance with this privacy policy. We may use your Data for the above purposes if we deem it necessary to do so for our legitimate interests. If you are not satisfied with this, you have the right to object in certain circumstances. For the delivery of direct marketing to you via e-mail, we’ll need your consent, whether via an opt-in or soft opt-in: soft opt-in consent is a specific type of consent which applies when you have previously engaged with us (for example, you contact us to ask us for more details about a particular product/service, and we are marketing similar products/services). Under “soft opt-in” consent, we will take your consent as given unless you opt-out; for other types of e-marketing, we are required to obtain your explicit consent, that is, you need to take positive and affirmative action when consenting by, for example, checking a tick box that we’ll provide; if you are not satisfied about our approach to marketing, you have the right to withdraw consent at any time. We may use your Data to show you FairCode Ltd adverts and other content on other websites. If you do not want us to use your data to show you FairCode Ltd adverts and other content on other websites, please turn off the relevant cookies.</div>
            <div class="privacy--description">Who we share Data with</div>
            <div class="privacy--text">We may share your Data with the following groups of people for the following reasons: our employees, agents and/or professional advisors - to obtain their advice and/or skills while processing your requests; third party service providers who provide services to us which require the processing of personal data - to help third party service providers in receipt of any shared data to perform functions on our behalf to help ensure the Website runs smoothly; third party payment providers who process payments made over the Website - to enable third party payment providers to process user payments and refunds; relevant authorities - to facilitate the detection of crime or the collection of taxes or duties; in each case, in accordance with this privacy policy.</div>
            <div class="privacy--description">Keeping Data secure</div>
            <div class="privacy--text">We will use technical and organisational measures to safeguard your Data, for example: access to your account is controlled by a password and a username that is unique to you; we store your Data on secure servers; payment details are encrypted using SSL technology (typically you will see a lock icon or green address bar (or both) in your browser when we use this technology. Technical and organisational measures include measures to deal with any suspected data breach. If you suspect any misuse or loss or unauthorised access to your Data, please let us know immediately by contacting us via this e-mail address: info@faircode.tech. If you want detailed information on how to protect your information and your computers and devices against fraud, identity theft, viruses and many other online problems, please visit www.eff.org.</div>
            <div class="privacy--description">Data retention</div>
            <div class="privacy--text">Unless a longer retention period is required or permitted by law, we will only hold your Data on our systems for the period necessary to fulfill the purposes outlined in this privacy policy or until your request that the Data be deleted. Even if we delete your Data, it may persist on backup or archival media for legal, tax or regulatory purposes.</div>
            <div class="privacy--description">Your rights</div>
            <div class="privacy--text">You have the following rights in relation to your Data: Right to access - the right to request (i) copies of the information we hold about you at any time, or (ii) that we modify, update or delete such information. If we provide you with access to the information we hold about you, we will not charge you for this, unless your request is “manifestly unfounded or excessive.” Where are legally permitted to do so, we may refuse your request. If we refuse your request, we will tell you the reasons why; Right to correct - the right to have your Data rectified if it is inaccurate or incomplete; Right to erase - the right to request that we delete or remove your Data from our systems; Right to restrict our use of your Data - the right to “block” us from using your Data or limit the way in which we can use it; Right to data probability - the right to request that we move, copy or transfer your Data; Right to object - the right to object to our use of your Data including where we use it for our legitimate interests. To make enquiries, exercise any of your rights set out above, or withdraw your consent to the processing of your Data (where consent is our legal basis for processing your Data), please contact us via this e-mail address: legal@faircode.tech. If you are not satisfied with the way a complaint you make in relation to your Data is handled by us, you may be able to refer your complaint to the relevant data protection authority. For the UK, this is the Information Commissioner’s Office (ICO). The ICO’s contact details can be found on their website at https://ico.org.uk. It is important that the Data we hold about you is accurate and current. Please keep us informed if your Data changes during the period for which we hold it.</div>
            <div class="privacy--description">Transfer outside the European Economic Area</div>
            <div class="privacy--text">Data which we collect from you may be stored and processed in and transferred to countries outside of the European Economic Area (EEA). For example, this could occur if our servers are located in a country outside the EEA or one of our service providers is situated in a country outside the EEA. We also share information with our group companies, some of which are located outside the EEA. We will only transfer Data outside the EEA where it is compliant with data protection legislation and the means of transfer provides adequate safeguards in relation to your Data, e.g. by way of data transfer agreement, incorporating the current standard contractual clauses adopted by the European Commission, or by signing up to the EU-US Privacy Shield Framework, in the event that the organization in receipt of the Data is based in the United States of America. To ensure that your Data receives an adequate level of protection, we have put in place appropriate safeguards and procedures with the third parties we share your Data with. This ensures your Data is treated by those third parties in a way that is consistent with the Data Protection Laws.</div>
            <div class="privacy--description">Links to other websites</div>
            <div class="privacy--text">This Website may, from time to time, provide links to other websites. We have no control over such websites and are not responsible for the content of these websites. This privacy policy does not extend to your use of such websites. You are advised to read the privacy policy or statement of other websites prior to using them.</div>
            <div class="privacy--description">Changes of business ownership and control</div>
            <div class="privacy--text">FairCode Ltd may, from time to time, expand or reduce our business and this may involve the sale and/or transfer of control of all or part of FairCode Ltd. Data provided by Users will, where it is relevant to any part of our business so transferred, be transferred along with that part and the new owner or newly controlling party will, under the terms of this privacy policy, be permitted to use the Data for the purposes for which it was originally supplied to us. We may also disclose Data to a prospective purchaser of our business or any part of it. In the above instances, we will take steps with the aim of ensuring your privacy is protected.</div>
            <div class="privacy--description">General</div>
            <div class="privacy--text">You may not transfer any of your rights under this privacy policy to any other person. We may transfer our rights under this privacy policy where we reasonably believe your rights will not be affected. If any court or competent authority finds that any provision of this privacy policy (or part of any provision) is invalid, illegal or unenforceable, that provision will, to the extent required, be deemed to be deleted, and the validity and enforceability of the other provisions of this privacy policy will not be affected. Unless otherwise agreed, no delay, act or omission by a party in exercising any right or remedy will be deemed a waiver of that, or any other, right or remedy. This agreement will be governed by and interpreted according to the law of England and Wales. All disputes arising under the Agreement will be subject to the exclusive jurisdiction of the English and Welsh courts.</div>
            <div class="privacy--description">Changes to this privacy policy</div>
            <div class="privacy--text">FairCode Ltd reserves the right to change this privacy policy as we may deem necessary from time to time or as may be required by law. Any changes will be immediately posted on the Website and you are deemed to have accepted the terms of the privacy policy on your first use of the Website following the alterations. You may contact FairCode Ltd by email at legal@faircode.tech.</div>
          </div>
        </div>
      </div>
    </div>
    <bottom-panel></bottom-panel>
  </div>
</template>

<script>
import TopPanel from '@/components/top_panel/TopPanel'
import BottomPanel from '@/components/bottom_panel/BottomPanel'
import CookiePanel from '@/components/cookie_panel/CookiePanel'

export default {
  name: 'Privacy',
  components: {
    TopPanel,
    BottomPanel,
    CookiePanel
  }
}
</script>

<style lang="scss" scoped>
  @import './privacy';
</style>
